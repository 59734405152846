import { useEffect, useRef } from 'react'
import katex from 'katex'

export const KaTeXComponent = ({ math, settings }) => {
  const containerRef = useRef()

  useEffect(() => {
    katex.render(math.replaceAll('align', 'aligned'), containerRef.current, {
      ...settings,
      throwOnError: true
    })
  }, [math])

  return <span ref={containerRef} />
}
