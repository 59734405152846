import {
  Block,
  ExtendedRecordMap,
  MissingBlock,
  RecordMap
} from '@simpledotink/notion-types'
import { parsePageId } from '@simpledotink/notion-utils'
import md5 from 'md5'
import { PageProps } from './types'

export function refixChildrenBlocks(recordMap: ExtendedRecordMap) {
  for (const id in recordMap.block) {
    const block = recordMap.block[id]?.value
    if (block && block.content) {
      block.content.forEach((blockId) => {
        const vBlock = recordMap.block[blockId]?.value
        if (vBlock)
          recordMap.block[blockId].value = {
            ...vBlock,
            parent_id: id
          }
      })
    }
  }

  return recordMap
}

export function blocksFetch(blocks: MissingBlock[]) {
  const key = md5(blocks)

  if (sessionStorage && sessionStorage.getItem(key))
    return JSON.parse(sessionStorage.getItem(key))

  return fetch('/api/get-blocks', {
    method: 'POST',
    body: JSON.stringify({
      blocks: blocks?.map((block) => ({
        pointer: {
          ...block
        }
      }))
    })
  })
    .then((res) => res.json())
    .then((response) => {
      const value = response?.recordMap
      if (value) sessionStorage.setItem(key, JSON.stringify(value))
      return value
    })
}

export async function getImageBlocks(
  recordMap: RecordMap,
  blockIds: string[],
  type: string
) {
  const missingPictureBlocks: Array<{ table: string; id: string }> = []
  const getImageBlock = (block: Block) =>
    block?.content?.find(
      (blockId) => blocks?.[blockId]?.value?.type === 'image'
    )

  let steps = 3
  blockIds = blockIds || []
  let blocks = recordMap?.block
  const pictureBlocks: Record<string, Block> = {}
  if (blocksFetch && type === 'page_content')
    while (steps > 0) {
      --steps
      for (const id of blockIds) {
        const block = blocks?.[id]?.value
        if (!block?.content || getImageBlock(block)) {
          const blockId = getImageBlock(block)
          if (!block) {
            missingPictureBlocks.push({ table: 'block', id })
          } else if (blockId) {
            pictureBlocks[id] = blocks[blockId]?.value
          }

          continue
        }

        const columnList: Array<{ table: string; id: string }> = []

        block.content.forEach((blockId) => {
          const block = blocks[blockId]?.value
          if (!block) missingPictureBlocks.push({ table: 'block', id: blockId })

          if (block?.type === 'column_list') {
            columnList.push(
              ...(block?.content || []).map((id) => ({
                table: 'block',
                id
              }))
            )
          }
        })

        if (columnList.every(({ id }) => !blocks[id]?.value?.content)) {
          missingPictureBlocks.push(...columnList)
        } else {
          // loop through the column list content and fetch those instead
          const columnListContent: Array<{ table: string; id: string }> = []
          columnList.forEach(({ id }) => {
            const block = blocks[id]?.value
            if (block?.content) {
              columnListContent.push(
                ...block.content.map((id) => ({ table: 'block', id }))
              )
            }
          })

          if (columnListContent.every(({ id }) => !blocks[id]?.value)) {
            missingPictureBlocks.push(...columnListContent)
          } else {
            for (const contentBlock of columnListContent.map(
              (b) => blocks[b.id]
            )) {
              if (contentBlock?.value?.type === 'image') {
                pictureBlocks[id] = contentBlock.value
                break
              }
            }
          }
        }
      }

      if (missingPictureBlocks.length === 0) break

      const rM = await blocksFetch(missingPictureBlocks)

      blocks = Object.assign({}, blocks, rM.block)
      missingPictureBlocks.length = 0
    }

  return { ...pictureBlocks, 'null-stopper': null }
}

export async function fetchMissingBlocks(blocks: MissingBlock[]) {
  let response: any = {}
  try {
    response = await (
      await fetch('/api/get-blocks', {
        method: 'POST',
        body: JSON.stringify({
          blocks: blocks?.map((block) => ({
            pointer: {
              ...block
            }
          }))
        })
      })
    ).json()
  } catch (e) {
    // console.log(e, 'error')
  }
  return response?.recordMap
}

export async function queryCollection(
  collectionId: string,
  collectionViewId: string,
  reducers
) {
  let response: any = {}
  try {
    response = await (
      await fetch('/api/query-collection', {
        method: 'POST',
        body: JSON.stringify({ collectionId, collectionViewId, reducers })
      })
    ).json()
  } catch (e) {
    console.log(e)
  }
  return response
}

export function evaluatePageRevalidation(props: PageProps) {
  let revalidateNr = 1
  if (!props || !props?.site) return revalidateNr
  const pagesCount = Object.keys(props?.siteMaps || {}).length
  const isPremium = props.site.paidPlan

  if (isPremium && pagesCount > 0)
    revalidateNr = Math.round((pagesCount / 20) * 10)

  // increase revalidation time
  return revalidateNr
}

export async function fetchPages(page: Array<{ id: string; spaceId: string }>) {
  const input = {
    page: { id: '', spaceId: '' },
    limit: 100,
    cursor: { stack: [] },
    chunkNumber: 0,
    verticalColumns: false
  }

  const requests = page.map((p) => ({
    ...input,
    page: { id: parsePageId(p.id) }
  }))

  let response: any = {}
  try {
    response = await (
      await fetch('/api/get-page', {
        method: 'POST',
        body: JSON.stringify({ requests })
      })
    ).json()
  } catch (e) {}
  return response
}

export const swapKeyAndValue = (siteMaps: any) => {
  const swappedSiteMap = {}

  Object.keys(siteMaps).forEach((key) => {
    const value = siteMaps[key]
    if (typeof value === 'object' && value.custom_slug && value.page_id) {
      swappedSiteMap[value.custom_slug] = key
      swappedSiteMap[value.page_id] = key
    } else {
      swappedSiteMap[value] = key
    }
  })

  return swappedSiteMap
}
