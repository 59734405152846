import { verifyEmailProtectedViewLink } from 'lib/verify-email-protected-view-link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export function useCheckEmailProtection(
  siteUUID: string | undefined,
  pageUUID: string | undefined,
  isPageEmailProtected: boolean
) {
  const router = useRouter()
  const token = router.query.token as string

  const [status, setStatus] = useState({
    isChecking: true,
    isAuthenticated: false
  })

  useEffect(() => {
    if (!siteUUID || !pageUUID || !router.isReady) return

    if (!isPageEmailProtected) {
      setStatus({ isChecking: false, isAuthenticated: false })
      return
    }

    if (token) {
      setStatus({ isChecking: true, isAuthenticated: false })
      verifyEmailProtectedViewLink(siteUUID, pageUUID, token).then(
        (isverified) => {
          setStatus({ isChecking: false, isAuthenticated: isverified })
        }
      )
    } else {
      setStatus({ isChecking: false, isAuthenticated: false })
    }
  }, [pageUUID, router.isReady])

  return status
}
