import { getBlockTitle, uuidToId } from '@simpledotink/notion-utils'
import slugify from 'slugify'

/**
 *
 * @param pageId
 * @param recordMap
 * @param param2
 * @returns
 */
export const getCanonicalPageId = (
  pageId: string,
  recordMap: any,
  { uuid = true }: { uuid?: boolean } = {},
  siteMaps: any,
  site?:any,
): string | null =>  {
  if (!pageId || !recordMap) return null

  const page = site.pages?.find((page) => page.pageId === pageId);
  const slug: string = page ? page.slug : '';
  const id = uuidToId(pageId)
  const block: any = recordMap.block[pageId]?.value

  if (block) {
    const title = slugify(getBlockTitle(block, recordMap), {
      lower: true,
      remove: /[/*+~.()'"!:@?-]/g
    })

    if (title) {
      if(site.siteId === "5feffab2-e6d9-40e0-8380-b98bb0ff92cc"){
        return `${slug}`
      }
     else if (uuid || !siteMaps[title]) {
        return `${title}-${id}`
      } else if (siteMaps[title]) {
        return title
      } else {
        return `${title}-${id}`
      }
    }
  }

  return id
}
