import React, { useCallback } from 'react'
import type {
  Block,
  ExtendedRecordMap,
  MissingBlock
} from '@simpledotink/notion-types'
import { hashKey } from '@simpledotink/notion-utils'
import {
  fetchMissingBlocks,
  fetchPages,
  getImageBlocks,
  queryCollection,
  refixChildrenBlocks
} from '../lib/utils'

export function useRetrieveBlocks(
  data: ExtendedRecordMap,
  setData: React.Dispatch<React.SetStateAction<ExtendedRecordMap>>,
  setFetchHashmap: React.Dispatch<React.SetStateAction<Record<string, unknown>>>
) {
  const getBlocks = useCallback(
    (blocks: MissingBlock[]) => {
      if (blocks.length !== 0 && Object.keys(data || {}).length !== 0) {
        const key = hashKey({ data: blocks })
        setFetchHashmap((v) => ({ ...v, [key]: { status: 'pending' } }))

        fetchMissingBlocks(blocks).then((rM) => {
          if (!rM) {
            setFetchHashmap((v) => ({ ...v, [key]: { status: 'failed' } }))
            return
          }

          const uData = { ...data }

          if (Object.keys(rM).length === 0) {
            rM.block = {}
            for (const block of blocks) {
              rM.block[block.id] = { role: 'none' }
            }
          }

          for (const key in rM) {
            uData[key] = { ...uData[key], ...rM[key] }
          }

          setFetchHashmap((v) => ({
            ...v,
            [key]: { status: 'success', value: rM }
          }))
          setData(refixChildrenBlocks(uData))
        })
      }
    },
    [data, setFetchHashmap, setData]
  )

  const getCollection = useCallback(
    (collectionId: string, collectionViewId: string, reducers: any) => {
      const uData = { ...data }
      // stop the fetching if the recordmap was not fetched
      if (Object.keys(data || {}).length === 0) return

      const key = hashKey({ collectionId, collectionViewId, data: reducers })

      setFetchHashmap((v) => ({ ...v, [key]: { status: 'pending' } }))

      queryCollection(collectionId, collectionViewId, reducers).then(
        (response: any) => {
          if (!response) {
            setFetchHashmap((v) => ({ ...v, [key]: { status: 'failed' } }))
            return
          }

          const { result, recordMap } = response
          uData.collection_query[collectionId] = {
            ...uData.collection_query[collectionId],
            [collectionViewId]: {
              ...uData.collection_query[collectionId]?.[collectionViewId],
              ...result?.reducerResults
            }
          }

          for (const key in recordMap) {
            uData[key] = { ...uData[key], ...recordMap[key] }
          }

          setFetchHashmap((v) => ({
            ...v,
            [key]: { value: response, status: 'success' }
          }))
          setData(refixChildrenBlocks(uData))
        }
      )
    },
    [data, setData, setFetchHashmap]
  )

  const retrieveImageBlocks = useCallback(
    (blockIds: string[], type: string) => {
      const key = hashKey({ data: blockIds, type, block: 'image' })
      setFetchHashmap((v) => ({ ...v, [key]: { status: 'pending' } }))
      return new Promise((resolve: (data: Record<string, Block>) => void) => {
        getImageBlocks(data, blockIds, type).then(
          (data: Record<string, Block>) => {
            setFetchHashmap((v) => ({
              ...v,
              [key]: { value: data, status: 'success' }
            }))
            resolve(data)
          }
        )
      })
    },
    [data, setFetchHashmap]
  )

  const retrievePage = useCallback(
    (pages: Array<{ id: string; spaceId: string }>) => {
      const key = hashKey({ data: pages })
      setFetchHashmap((v) => ({ ...v, [key]: { status: 'pending' } }))
      fetchPages(pages)
        .then((page) => {
          setFetchHashmap((v) => ({
            ...v,
            [key]: { status: 'success' },
            value: page
          }))

          const uData = { ...data }
          const rM = page.recordMap

          // if the view has already been fetched, no need to scatter it.
          for (const key in rM?.collection_view || {}) {
            if (uData?.collection_view[key]) delete rM?.collection_view[key]
          }

          for (const key in rM) {
            uData[key] = { ...uData[key], ...rM[key] }
          }

          setData(refixChildrenBlocks(uData))
        })
        .catch((e) => {
          console.log(e)
        })
    },
    [data, setFetchHashmap, setData]
  )
  return { getBlocks, retrieveImageBlocks, getCollection, retrievePage }
}
