export function embedGifs() {
  const figures = document.querySelectorAll('figure')

  if (!figures) return

  for (let i = 0; i < figures.length; i++) {
    const video = figures[i].querySelector(
      'video'
    ) as unknown as HTMLVideoElement

    // if the gif wasn't loaded, embed it as iframe
    if (video && video.readyState !== 4) {
      // create an iframe and replace the figure with it.
      const iframe = document.createElement('iframe') as HTMLIFrameElement
      iframe?.setAttribute('style', 'width: 100%; height:400px;')
      iframe.src = video.src
      video.parentElement.replaceChild(iframe, video)
    }
  }
}

export function embedLinks() {
  // this function will turn a [link] with square braces to a button and also
  const links = document.querySelectorAll(
    '.notion-link'
  ) as unknown as HTMLAnchorElement[]
  for (let i = 0; i < links.length; i++) {
    // skip for links inside blockquote
    if (links[i].parentElement.nodeName === 'BLOCKQUOTE') continue
    let match = links[i].innerHTML.match(/\[(.*)\]/)

    if (match) {
      links[i].innerHTML = match[1]
      links[i].classList.add('simple-ink-button')
    }

    // also customize link target
    match = links[i].innerHTML.match(/#INTERNAL:(.*)/)

    if (match) {
      links[i].innerHTML = match[1]
      links[i].target = '_self'
    }
  }
}

export function includeBaseClass() {
  const missingClasses = {
    'notion-page-content-inner': 'notion-page-content',
    'notion-page-cover': 'notion-page-cover-wrapper'
  }

  for (const key in missingClasses) {
    if (document?.querySelector(`.${key}`)) {
      const parent = document.querySelector(`.${key}`)?.parentElement
      if (!parent.classList.contains(missingClasses[key]))
        parent.classList.add(missingClasses[key])
    }
  }
}

export function embedCode() {
  // this function will be called when this react component has finished rendering
  const blocks = document.querySelectorAll(
    '.notion-code'
  ) as unknown as HTMLDivElement[]
  for (let i = 0; i < blocks.length; i++) {
    let code: any = blocks[i]?.innerText
      ?.replace(/\n/g, '')
      ?.match(/^#EMBED(.*)#EMBED$/)
    // if this code does not require embed, stop here
    if (!code) continue
    code = code[1]

    const scriptRegex = /<script .*?src=["'](.+)["']><\/script>/

    let scripts = []

    while (scriptRegex.test(code)) {
      const script = code.match(scriptRegex)
      code = code.replace(scriptRegex, '')

      if (script?.[1]) scripts = [...new Set([...scripts, script[1]])]
    }

    for (const script of scripts) {
      const scriptTag = document.createElement('script')
      scriptTag.src = script
      document.head.appendChild(scriptTag)
    }

    // convert the code string to javascript dom
    const dom = document
      .createRange()
      .createContextualFragment(code.replace(/\\"/, `"`))
    const virtualNode = document.createElement('div')
    blocks[i].parentElement.replaceChild(virtualNode, blocks[i])

    // loop through the code and nodes to the dom
    for (const node of dom.childNodes) {
      virtualNode.appendChild(node)
    }
  }
}

export function embedHeader(site: { domain: string; name: string }) {
  // the first blockquote inside a synced block
  const quoteSync = document.querySelector(
    '.notion-page-content-inner>*:first-child>*:first-child'
  ) as HTMLDivElement

  // the first element on the page
  const quoteNotSync = document.querySelector(
    '.notion-page-content-inner>*:first-child'
  ) as any

  const block =
    quoteNotSync?.nodeName === 'BLOCKQUOTE'
      ? quoteNotSync
      : quoteSync?.nodeName === 'BLOCKQUOTE'
      ? quoteSync
      : null

  // due to fetching children of a block, this particular script runs twice. This is a fix for that problem.
  if (document.querySelector('.custom-header') && block) {
    block.parentElement.removeChild(block)
  } else if (block) {
    document
      .querySelector('.notion-header')
      ?.setAttribute('style', 'opacity: 0; position: absolute; z-index: -1000;')
    const seperator = /(,|<span style="padding: *0.5em;*"><\/span>)/

    const header = document.createElement('header')
    header?.setAttribute('class', 'custom-header-container')
    let children = block.innerHTML
      .replace(/(notion-link|_blank)/g, '')
      .split(seperator)
      .filter((s: string) => !seperator.test(s))
      .map((c: string) => c.trim())

    const searchIndex = children.findIndex((c) => /\[search\]/gi.test(c))

    if (searchIndex !== -1) {
      const nChildren = []

      for (let i = 0; i < children.length; i++) {
        let c = children[i]
        if (i === searchIndex) {
          c = `
          <div role="button" class="custom-search-btn">
          <svg class="searchIcon" viewBox="0 0 17 17">
          <path d="M6.78027 13.6729C8.24805 13.6729 9.60156 13.1982 10.709 12.4072L14.875 16.5732C15.0684 16.7666 15.3232 16.8633 15.5957 16.8633C16.167 16.8633 16.5713 16.4238 16.5713 15.8613C16.5713 15.5977 16.4834 15.3516 16.29 15.1582L12.1504 11.0098C13.0205 9.86719 13.5391 8.45215 13.5391 6.91406C13.5391 3.19629 10.498 0.155273 6.78027 0.155273C3.0625 0.155273 0.0214844 3.19629 0.0214844 6.91406C0.0214844 10.6318 3.0625 13.6729 6.78027 13.6729ZM6.78027 12.2139C3.87988 12.2139 1.48047 9.81445 1.48047 6.91406C1.48047 4.01367 3.87988 1.61426 6.78027 1.61426C9.68066 1.61426 12.0801 4.01367 12.0801 6.91406C12.0801 9.81445 9.68066 12.2139 6.78027 12.2139Z"></path></svg><span class="title">Search</span></div>`
        }

        nChildren.push(c)
      }

      children = nChildren
    }

    if (children) {
      const links = children
        .map((c) => {
          return (
            '<div class="custom-header__links__link">' +
            (c.startsWith('<a') ? c : '<div>' + c + '</div>') +
            '</div>'
          )
        })
        .join(' ')

      header.innerHTML = `
    <div class="custom-header">
      <a class="custom-header__title" href="https://${site.domain}">
          ${site.name}
      </a>
      <span class="material-icons custom-header__menu menu-open">menu</span>
      <span class="material-icons custom-header__menu menu-close">close</span>
      <div class="custom-header__links">
        ${links}
      </div>

      <div class="custom-header__mobile-links">
        ${links}
      </div>
    </div>`

      document.querySelector('.notion-app')?.prepend(header)
    }

    // add event listener to the menu buttons both for mobile and pc
    setTimeout(() => {
      document
        ?.querySelector('.custom-header__menu.menu-open')
        ?.addEventListener('click', () => {
          document
            .querySelector('.custom-header__menu')
            .classList.add('custom-header__menu--open')

          document
            .querySelector('.custom-header__mobile-links')
            .classList.add('custom-header__mobile-links--open')
        })

      document
        .querySelector('.custom-header__menu.menu-close')
        ?.addEventListener('click', () => {
          document
            .querySelector('.custom-header__menu')
            .classList.remove('custom-header__menu--open')

          document
            .querySelector('.custom-header__mobile-links')
            .classList.remove('custom-header__mobile-links--open')
        })

      const customSearch = document.querySelectorAll('.custom-search-btn')

      if (!document.querySelector('.notion-search-button')) {
        customSearch.forEach((el) => el.parentElement.removeChild(el))
      } else if (searchIndex !== -1) {
        customSearch.forEach((el) => {
          el.parentElement?.addEventListener('click', () => {
            const realSearch = document.querySelector('.notion-search-button')

            if (realSearch) (realSearch as any).click()
          })
        })
      }
    }, 500)

    // return missing wrapper class
    block.parentElement.parentElement.classList.add('notion-page-content')

    block.parentElement?.removeChild(block)
  }
}

export function cleanCode(code: string) {
  return code.replace(/[a-zA-Z0-9]+(.setAttribute)/g, (s) =>
    s.replace(/.setAttribute/g, '?.setAttribute')
  )
}
