export function getFont(font: string) {
  // Return empty for Notion's template default font
  if (font === 'Default') {
    return ''
  }

  // Web default font
  const webfonts = [
    'Arial',
    'Verdana',
    'Helvetica',
    'Tahoma',
    'Trebuchet MS',
    'Times New Roman',
    'Georgia',
    'Courier New',
    'Brush Script MT'
  ]
  if (webfonts.includes(font)) {
    return `
        <style>
            .notion{
                font-family: '${font}', sans-serif !important;
            }
        <style>
        `
  }

  // From Google Font
  return `
        <style>
            @import url('https://fonts.googleapis.com/css2?family=${font.replace(/\s/g, '+')}&display=swap');
            .notion{
            font-family: '${font}', sans-serif !important;
            }
        </style>
    `
}
