import * as React from 'react'
import Head from 'next/head'
import Link from 'next/link'
import Image from 'next/image'
import dynamic from 'next/dynamic'
import cs from 'classnames'
import { useRouter } from 'next/router'
import { useSearchParam } from 'react-use'
import BodyClassName from 'react-body-classname'
import useDarkMode from 'use-dark-mode'
import { PageBlock } from '@simpledotink/notion-types'
import parse from 'html-react-parser'
import { getFont } from '../lib/get-font'
import { Tweet } from 'react-twitter-widgets'

// core notion renderer
import {
  NotionRenderer,
  Code,
  Collection,
  CollectionRow,
  defaultMapImageUrl
} from '@simpledotink/react-notion-x'

// utils
import { getBlockTitle } from '@simpledotink/notion-utils'
import { mapPageUrl, getCanonicalPageUrl } from 'lib/map-page-url'
import { getPageDescription } from 'lib/get-page-description'
import { getPageTweet } from 'lib/get-page-tweet'
import { searchNotion } from 'lib/search-notion'
import * as types from 'lib/types'
import * as config from 'lib/config'

// components
import { CustomFont } from './CustomFont'
import { Loading } from './Loading'
import { Page404 } from './Page404'
import { PageHead } from './PageHead'
import { PageActions } from './PageActions'
import { Footer } from './Footer'

import styles from './styles.module.css'
import {
  embedCode,
  embedLinks,
  embedHeader,
  includeBaseClass,
} from 'lib/embeds'
import { PDFEmbed } from './PDF'
import { useEffect, useState } from 'react'
import { useRetrieveBlocks } from 'hooks/useRetrieveBlocks'
import { swapKeyAndValue } from 'lib/utils'
import { useCheckEmailProtection } from 'hooks/useCheckEmailProtection'
import { KaTeXComponent } from './Equation'

// const Equation = dynamic(() =>
//   import('@simpledotink/react-notion-x').then((notion) => notion.Equation)
// )

// we're now using a much lighter-weight tweet renderer react-static-tweets
// instead of the official iframe-based embed widget from twitter
// const Tweet = dynamic(() => import('react-tweet-embed'))

const Modal = dynamic(
  () => import('@simpledotink/react-notion-x').then((notion) => notion.Modal),
  { ssr: false }
)

function Tweeter(props) {
  return <Tweet tweetId={props.id} />
}

const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
]

const updates = []

export const NotionPage: React.FC<types.PageProps> = ({
  site,
  recordMap,
  error,
  pageId,
  isSubPage,
  isCanonical,
  pageData,
  siteMaps
}) => {
  const [isPasswordProtected, setIsPasswordProtected] = React.useState(false)
  const [recordData, setRecordData] = useState(recordMap)
  const [weekStartsOn, setWeekStartsOn] = React.useState(6)
  const [hideProperties, setHideProperties] = useState(false)
  const [enableSearch, setEnableSearch] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  // const [prefetch, setPrefetch] = useState(true)

  const [fetchHashmap, setFetchHashmap] = useState({})

  const updateData = React.useCallback(
    (d) => {
      updates.push(d)
      const uData = recordData
      for (const r of updates) {
        for (const key in r) {
          uData[key] = { ...uData[key], ...r[key] }
        }
      }

      setRecordData(uData)
    },
    [recordData, setRecordData]
  )

  const { getBlocks, getCollection, retrieveImageBlocks, retrievePage } =
    useRetrieveBlocks(recordData, updateData, setFetchHashmap)

  useEffect(() => {
    setRecordData(recordMap)
    setFetchHashmap({})
  }, [recordMap])

  // for email protection
  const emailAuthStatus = useCheckEmailProtection(
    site?.siteId,
    pageData?.pageId,
    pageData?.isEmailProtected
  )

  useEffect(() => {
    // check if the page is email protected and if the user is authenticated
    if (pageData && pageData.isEmailProtected) {
      if (!emailAuthStatus.isChecking && !emailAuthStatus.isAuthenticated) {
        const redirectLink = window.location.href.split('?')[0]
        router.replace(
          `/_/si/email-protected-page?pageId=${pageData.pageId}&siteId=${site.siteId}&redirectLink=${redirectLink}`
        )
      } else if (emailAuthStatus.isAuthenticated) setIsLoading(false)
    } else if (pageData && !pageData.isEmailProtected) {
      setIsLoading(false)
    }
  }, [pageData, emailAuthStatus.isChecking, emailAuthStatus.isAuthenticated])

  // For password protection
  useEffect(() => {
    const isPageAuthenticated = (pageId) => {
      const cookie = document.cookie.split('; ')
      return !!cookie.find((c) => c.startsWith(`safe_to_page_${pageId}=true`))
    }
    if (pageData) {
      let autoPass = false

      if (
        isPageAuthenticated(pageData.pageId) ||
        isPageAuthenticated(site.rootNotionPageId)
      )
        autoPass = true

      if (pageData.isPasswordProtected && !autoPass) {
        setIsPasswordProtected(true)
        const redirectLink = window.location.href
        window.location.href = `/_/si/protected-page?pageId=${pageData.pageId}&siteId=${site.siteId}&redirectLink=${redirectLink}`
      }
    }
  }, [pageData])

  useEffect(() => {
    if (site) {
      embedLinks()
      includeBaseClass()
      embedHeader(site)
      setWeekStartsOn(
        days.indexOf(site.calendarWeekStartsOn?.toLocaleLowerCase())
      )
      setHideProperties(site.hidePropertiesOnDatabasePages)
      setEnableSearch(site.searchEnabled)
      // leave the embeds here, removing break embeds.926abd95785b4885a25209d9e924fad2
      setTimeout(embedCode, 0)
    }
  })
  const router = useRouter()

  const lite = useSearchParam('lite')

  const params: any = {}
  if (lite) params.lite = lite

  // lite mode is for oembed
  const isLiteMode = lite === 'true'
  const searchParams = new URLSearchParams(params)

  const darkMode = useDarkMode(false, { classNameDark: 'dark-mode' })

  useEffect(() => {
    // clear the old state in case the page is updated
    localStorage.removeItem('darkMode')

    let appearanceIsDark = false

    if (site && site.appearance === 'user-setting')
      if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
      )
        appearanceIsDark = true

    if (site && site.appearance === 'dark') appearanceIsDark = true

    if (appearanceIsDark) darkMode.enable()
    else darkMode.disable()
  }, [site])

  // useEffect(() => {
  //   /*
  //     Allow prefetch for pages with less than 20 links
  //   */
  //   if (site?.paidPlan && recordData) {
  //     const linksCount = Object.entries(recordData.block)
  //       .map((entry) => entry[1]?.value)
  //       .filter((b) => b && b.type === 'page').length

  //     const linksIsBelowLimit = linksCount <= 20
  //     setPrefetch(linksIsBelowLimit)
  //   }
  // }, [site, siteMaps])

  const [syncing, setSyncing] = useState(false)
  const [deleteSyncedPages, setDeleteSyncedPages] = useState(false)

  useEffect(() => {
    const url = router.query
    if (url.syncing === 'yes') {
      setSyncing(true)
      router.push(`/sync?token=${url.token}`)
    }
    if (url.unsync === 'yes') {
      setDeleteSyncedPages(true)
      router.push(`/unsync?id=${url.id}&token=${url.token}`)
    }
  }, [router.query])

  useEffect(() => {
    if (site?.isRtl) {
      const dir = site.isRtl ? 'rtl' : 'ltr'
      document.querySelector('body')?.setAttribute('dir', dir)
      document
        .getElementsByTagName('html')[0]
        ?.setAttribute('lang', site.siteLanguageCode)
    }
  }, [site])

  if (router.isFallback || isLoading) return <Loading />

  const keys = Object.keys(recordMap?.block || {})
  const block = recordMap?.block?.[keys[0]]?.value

  if (error || !site || !keys.length || !block) {
    return <Page404 site={site} pageId={pageId} error={error} />
  }

  const title = isSubPage
    ? pageData.seoTitle || getBlockTitle(block, recordMap)
    : site.name

  if (!config.isServer) {
    // add important objects to the window global for easy debugging
    const g = window as any
    g.pageId = pageId
    g.recordMap = recordMap
    g.block = block
  }

  const swappedSiteMap = swapKeyAndValue(siteMaps) // swapping key-value of map, for easier lookup
  const siteMapPageUrl = mapPageUrl(
    site,
    recordMap,
    searchParams,
    isCanonical,
    siteMaps
  )
  let canonicalPageUrl =
    isCanonical &&
    getCanonicalPageUrl(site, recordMap, isCanonical, swappedSiteMap)(pageId)

  canonicalPageUrl = !isSubPage ? `https://${site.domain}` : canonicalPageUrl

  // const isRootPage =
  //   parsePageId(block.id) === parsePageId(site.rootNotionPageId)
  const isBlogPost =
    block.type === 'page' && block.parent_table === 'collection'
  // const showTableOfContents = !!isBlogPost
  const showBreadcrumb = site.showBreadcrumb
  const showTableOfContents = site.showTableOfContent
  const minTableOfContentsItems = 3

  let socialImage =
    site.socialMedia ||
    defaultMapImageUrl(
      (block as PageBlock).format?.page_cover || site.socialMedia,
      block
    )

  let socialDescription =
    getPageDescription(block, recordMap) ?? site.description

  // if (isSubPage) {
  socialDescription = pageData?.seoDescription || socialDescription || ''
  if (pageData?.previewImageLink) {
    socialImage = pageData.previewImageLink
  }
  // }

  const comments: React.ReactNode = null
  let pageAside: React.ReactChild = null

  // only display comments and page actions on blog post pages
  if (isBlogPost) {
    const tweet = getPageTweet(block, recordMap)
    if (tweet) {
      pageAside = <PageActions tweet={tweet} />
    }
  }

  // Check favicon
  let favicon = site.favicon
  if (favicon) {
    // Emoji means no https link
    if (!favicon.includes('https://') && !favicon.includes('http://')) {
      favicon = `data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22><text y=%22.9em%22 font-size=%2290%22>${favicon}</text></svg>`
    }
  }

  // Custom Font
  const font = site.font

  if (isPasswordProtected) {
    console.info('is password protected')
    return <Loading />
  }

  return (
    <>
      <PageHead site={site} />

      <Head>
        <link
          rel='shortcut icon'
          href={getFavicon(favicon, '32') || '/favicon.png'}
        />

        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href={getFavicon(favicon, '180') || '/favicon.png'}
        />
        <link
          rel='icon'
          type='image/png'
          sizes='96x96'
          href={getFavicon(favicon, '96') || '/favicon-96x96.png'}
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href={getFavicon(favicon, '32') || '/favicon-32x32.png'}
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href={getFavicon(favicon, '16') || '/favicon-16x16.png'}
        />
        { isSubPage && (
            <>
        <meta property='og:title' content={title} />
        <meta property='og:site_name' content={site.name} />

        <meta name='twitter:title' content={title} />
        <meta property='twitter:domain' content={site.domain} />
        <meta httpEquiv='content-language' content={site.siteLanguageCode} />

        {socialDescription && (
          <>
            <meta name='description' content={socialDescription} />
            <meta property='og:description' content={socialDescription} />
            <meta name='twitter:description' content={socialDescription} />
          </>
        )}

        {socialImage ? (
          <>
            <meta name='twitter:card' content='summary_large_image' />
            <meta name='twitter:image' content={socialImage} />
            <meta property='og:image' content={socialImage} />
          </>
        ) : (
          <meta name='twitter:card' content='summary' />
        )}

        {canonicalPageUrl && (
          <>
            {/* <link
              rel='canonical'
              href={pageData?.slug ? pageData.slug : canonicalPageUrl}
            /> */}
            <meta property='og:url' content={canonicalPageUrl} />
            <meta property='twitter:url' content={canonicalPageUrl} />
          </>
        )}
            </>
        )}

        <title>{title}</title>
        {parse(`<style data-name="user-style">${site.cssCustomCode}</style>`)}


        {font && parse(getFont(font))}

        {isSubPage && pageData && !pageData.allowIndexPage && (
          <meta name='robots' content='noindex' />
        )}

        {isSubPage && parse(`${pageData.customCode}`)}
      </Head>

      <CustomFont site={site} />

      {isLiteMode && <BodyClassName className='notion-lite' />}

      {syncing && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '70%',
            transform: 'translateX(20%)'
          }}
        >
          Please wait while we sync your site and notion pages! Depending on the
          size of your site, it may take a few seconds to some minutes. Please
          keep your notion page public until the update finishes.
          <Loading />
        </div>
      )}

      {deleteSyncedPages && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '70%',
            transform: 'translateX(20%)'
          }}
        >
          Please wait while we unsync your site and notion pages! After this you
          have to set your notion page to public in other for your site to work.
          <Loading />
        </div>
      )}

      <NotionRenderer
        bodyClassName={cs(
          styles.notion,
          pageId === site.rootNotionPageId && 'index-page'
        )}
        components={{
          pageLink: ({
            href,
            as,
            passHref,
            replace,
            scroll,
            shallow,
            locale,
            ...props
          }) => (
            <Link
              href={href}
              as={as}
              passHref={passHref}
              // prefetch={prefetch}
              replace={replace}
              scroll={scroll}
              shallow={shallow}
              locale={locale}
            >
              <a {...props} />
            </Link>
          ),
          image: ({
            src,
            alt,

            width,
            height,

            className,
            style,

            ...rest
          }) => {
            const layout = width && height ? 'intrinsic' : 'fill'

            return (
              <Image
                {...rest}
                className={className}
                src={src}
                alt={alt}
                objectFit={style?.objectFit}
                objectPosition={style?.objectPosition}
                layout={layout}
              />
            )
          },
          code: Code,
          collection: Collection,
          collectionRow: CollectionRow,
          tweet: Tweeter,
          modal: Modal,
          // equation: Equation,
          equation: KaTeXComponent,
          pdf: PDFEmbed
        }}
        fetchBlocks={getBlocks}
        retrieveImageBlocks={retrieveImageBlocks}
        recordMap={recordData || recordMap}
        rootPageId={site.rootNotionPageId}
        fullPage={!isLiteMode}
        darkMode={darkMode.value}
        showBreadcrumb={showBreadcrumb}
        showTableOfContents={showTableOfContents}
        minTableOfContentsItems={minTableOfContentsItems}
        mapPageUrl={siteMapPageUrl}
        mapImageUrl={defaultMapImageUrl}
        searchNotion={enableSearch ? searchNotion(site.siteId) : null}
        pageFooter={comments}
        pageAside={pageAside}
        weekStartsOn={weekStartsOn}
        hidePageProperty={hideProperties}
        queryCollection={getCollection}
        fetchPage={retrievePage}
        fetchHashmap={fetchHashmap}
        navbar={site.navbar ?? null}
        footer={
          <Footer
            bodyCustomCode={site.bodyCustomCode}
            isPaidPlan={site.paidPlan && !site.subscriptionPlanType.startsWith('Starter') }
            footerData={site.footer}
          />
        }
      />
    </>
  )
}

function getFavicon(favicon, size) {
  if (!favicon) return null

  // if svg return directly
  if (favicon.includes('<svg')) {
    return favicon
  }

  // resize according to size
  return favicon + `-/resize/${size}x${size}/`
}
