import axios from 'axios'
import * as Sentry from '@sentry/nextjs'

export async function verifyEmailProtectedViewLink(
  siteUUID: string,
  pageUUID: string,
  token: string
) {
  const url = `${process.env.NEXT_PUBLIC_BASE_API}/pages/verify-token/${siteUUID}/${pageUUID}?token=${token}`
  return axios
    .post(url)
    .then((resp) => {
      return resp.data.verification_status as boolean
    })
    .catch((error) => {
      Sentry.captureException(error, {
        extra: {
          url,
          from: 'verify-email-protected-view-link.ts'
        }
      })
      return false
    })
}
