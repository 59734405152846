import { parsePageId, uuidToId } from '@simpledotink/notion-utils'

import { ExtendedRecordMap } from '@simpledotink/notion-types'
import { Site } from './types'
import { getCanonicalPageId } from './get-canonical-page-id'

// include UUIDs in page URLs during local development but not in production
// (they're nice for debugging and speed up local dev)

export const mapPageUrl =
  (
    site: Site,
    recordMap: ExtendedRecordMap,
    searchParams: URLSearchParams,
    isCanonical: boolean,
    siteMaps: any
  ) =>
  (pageId = '') => {
    if (pageId === site.rootNotionPageId) {
      return createUrl('/', searchParams)
    } else {
      if (site.isFreemium || site.paidPlan) {
        const relativeLInk = getRelativeLink(
          pageId,
          recordMap,
          isCanonical,
          siteMaps,
          site
        )

        /*
         * We need to escape the # character in the URL because it is used by
         * the browser to scroll to an element with the id in the URL instead of
         * making a request to the server.
         *
         */
        const escapedChar = ['#']
        let url = createUrl(`${relativeLInk}`, searchParams)

        url = escapedChar.some((char) => url.includes(char))
          ? encodeURIComponent(url)
          : url

        return `/${url}`
      } else {
        return createUrl(
          `https://create.simple.ink/page-not-built?site_id=${site.siteId}`,
          searchParams
        )
      }
    }
  }

export const getCanonicalPageUrl =
  (site: Site, recordMap: ExtendedRecordMap, isCanonical, siteMaps: any) =>
  (pageId = '') => {
    const pageUuid = parsePageId(pageId, { uuid: true })

    if (uuidToId(pageId) === site.rootNotionPageId) {
      return `https://${site.domain}`
    } else {
      const relativeLInk = getRelativeLink(
        pageUuid,
        recordMap,
        isCanonical,
        siteMaps,
        site,
      )
      return `https://${site.domain}/${relativeLInk}`
    }
  }

function createUrl(path: string, searchParams: URLSearchParams) {
  return [path, searchParams.toString()].filter(Boolean).join('?')
}

function getRelativeLink(
  pageId: string,
  recordMap,
  isCanonical,
  siteMaps: any,
  site: any,
) {
  const relativeLink = getCanonicalPageId(
    pageId,
    recordMap,
    {
      uuid: !isCanonical
    },
    siteMaps,
      site
  )

  // Todo: what about custom-slug?
  // if (siteMaps[pageId]) {
  //   relativeLink = siteMaps[pageId]
  // }

  return relativeLink
}
