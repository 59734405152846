import Head from 'next/head'
import * as React from 'react'
import * as types from 'lib/types'
import mainManifest from '../public/manifest.json'

export const PageHead: React.FC<types.PageProps> = ({ site }) => {
  React.useEffect(() => {
    const manifest = {
      ...mainManifest,
      name: site?.name,
      short_name: site?.name,
      description: site?.description,
      icons: [
        {
          src: site?.favicon,
          type: 'image/png',
          sizes: '16x16'
        },
        {
          src: site?.favicon,
          type: 'image/png',
          sizes: '32x32'
        },
        {
          src: site?.favicon,
          type: 'image/png',
          sizes: '96x96'
        },
        {
          src: site?.favicon,
          type: 'image/png',
          sizes: '180x180'
        },
        {
          src: site?.favicon,
          sizes: '192x192',
          type: 'image/png'
        },
        {
          src: site?.favicon,
          sizes: '512x512',
          type: 'image/png'
        }
      ],
      start_url: `https://${site?.domain}/`
    }
    const stringManifest = JSON.stringify(manifest)
    const blob = new Blob([stringManifest], { type: 'application/json' })
    const manifestURL = URL.createObjectURL(blob)

    setTimeout(() => {
      document.querySelector('#manifest')?.setAttribute('href', manifestURL)
    }, 200)
  }, [])

  return (
    <Head>
      <meta charSet='utf-8' />
      <meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1, shrink-to-fit=no'
      />

      <meta property='og:type' content='website' />
      <link rel='manifest' id='manifest' />
    </Head>
  )
}
