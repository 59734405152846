module.exports = {
  // where it all starts -- the site's root Notion page (required)
  rootNotionPageId: "",

  // if you want to restrict pages to a single notion workspace (optional)
  // (this should be a Notion ID; see the docs for how to extract this)
  rootNotionSpaceId: null,

  // basic site info (required)
  name: "My Blog",
  domain: "transitivebullsh.it",
  author: 'Simple.ink',

  // open graph metadata (optional)
  description: !!(process.env.SITE_DESCRIPTION) ? process.env.SITE_DESCRIPTION : 'Example site description',
  socialImageTitle: 'SimpleInk',
  socialImageSubtitle: 'Hello World! 👋',

  // Custom code that is sent from our backend
  cssCustomCode: !!(process.env.CSS_CUSTOM_CODE) ? process.env.CSS_CUSTOM_CODE : '',
  headCustomCode: !!(process.env.HEAD_CUSTOM_CODE) ? process.env.HEAD_CUSTOM_CODE : '',
  bodyCustomCode: !!(process.env.BODY_CUSTOM_CODE) ? process.env.BODY_CUSTOM_CODE : '',
  siteID: process.env.SITE_UUID || '',

  // default notion icon and cover images for site-wide consistency (optional)
  // page-specific values will override these site-wide defaults
  defaultPageIcon: !!(process.env.FAVICON_LINK) ? process.env.FAVICON_LINK : null,
  defaultPageCover: null,
  defaultPageCoverPosition: 0.5,

  // image CDN host to proxy all image requests through (optional)
  // NOTE: this requires you to set up an external image proxy
  imageCDNHost: null,

  // map of notion page IDs to URL paths (optional)
  // any pages defined here will override their default URL paths
  // example:
  //
  // pageUrlOverrides: {
  //   '/hello': '0c322c33381c49bca5083a451c334c39',
  // },
  pageUrlOverrides: null
}
