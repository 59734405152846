import * as React from 'react'
import SimpleInkBadger from './SimpleInkBadger'

import styles from './styles.module.css'

export const Footer: React.FC<{
  bodyCustomCode: string
  isPaidPlan: boolean
  footerData: any
}> = ({ bodyCustomCode, isPaidPlan,footerData }) => {
  
  return (
      <>
        { footerData ?  <footer className={styles.main_footer} style={{ minHeight: `${footerData.height}px`}} >
         <div  className={`${styles.inner_footer} ${footerData.alignment === 'column'? styles.inner_footer_inline_column : styles.inner_footer_inline} ${footerData.alignment_position === 'center' && footerData.alignment === 'row' ? styles.inner_footer_inline_center: footerData.alignment_position === "end" && footerData.alignment === 'row'  ? styles.inner_footer_inline_right :footerData.alignment_position === "end" && footerData.alignment === 'column' ? styles.inner_footer_column_right : footerData.alignment_position === "center" && footerData.alignment === 'column' ? styles.inner_footer_column_center : ""  } `}>
           <div className={styles.logo}>
             {footerData.logo_type === "emoji" ? (
            <samp style={{fontSize:'40px'}}>{footerData.logo_emoji}</samp>
                 ) : footerData.logo_type === "image" && footerData.logo_image !== '' ? (
                 <img src={footerData.logo_image} alt="Logo"  />
             ) : (
                 <div className={styles.main_footer_text}><h3 style={{color:'white'}}>{footerData.logo_text}</h3></div>
             )}

           </div>
           <div className={`${styles.footer_column} ${styles.footer_column_inline}`}>
             <ul  style={{padding:'0',display:'flex',listStyleType: "none"}}>
               {footerData.links?.sort((a, b) => a.sort_num - b.sort_num).map((link, index) => (
                   <li key={index} style={{paddingRight:'10px',paddingBottom:'5px'}} >
                     <a href={link.url} target={link.open_in_new_tab ?'_blank':'_self'} className={styles.footer_link}  >{link.title}</a>
                   </li>
               ))}
             </ul>
           </div>
         </div>
        </footer>   :  <footer className={styles.footer}>
          <div dangerouslySetInnerHTML={{ __html: bodyCustomCode }}></div>
        </footer> }
          {!isPaidPlan ? <SimpleInkBadger /> : null}
      </>



  )
}
